import React, { Component } from 'react'

import Img from 'gatsby-image/withIEPolyfill';
import CardStyle from './card.module.css'
import {RevealButton } from 'components/buttons'

export const PeopleCard = React.forwardRef(({featured_media, title, email,position, onClick}, ref) => {
	return (
		<div className={CardStyle.container} ref={ref} onClick={onClick}>
		  {featured_media && featured_media.localFile && featured_media.localFile.childImageSharp &&
		   <Img fluid={featured_media.localFile.childImageSharp.fluid} />
		  }
		  <div className={CardStyle.text}>
			<h5 className={CardStyle.name}>{title}</h5>
			<p className={CardStyle.position}>{position}</p>
		    {
			    email &&
				    (<RevealButton
                       className="my-2"
                       textClass={CardStyle.email}
                       text="Contact by email"
                    >
                     <a className={CardStyle.email} href={`mailto:${email.toLowerCase()}`}>{email.toLowerCase()}</a>
                     </RevealButton>)
		    }
          </div>
		</div>
	);
});
